import authReducer from "./authReducer";
import usersReducer from "./usersReducer";
import aboutReducer from "./aboutReducer";
import goalsReducer from "./goalsReducer";
import filesReducer from "./uploadedFiles";
import alertReducer from "./alert";
import programReducer from "./programReducer";
import courseReducer from "./courseReducer";
import diplomaReducer from "./diplomaReducer";
import communicationReducer from "./communicationReducer";
import publicationReducer from "./publicationReducer";
import bookReducer from "./bookReducer";
import thesisReducer from "./thesisReducer";
import seminarReducer from "./seminarReducer";
import postCourseReducer from "./postCourseReducer";
import supportReducer from "./supportReducer";
import formationReducer from "./formationReducer";
import ecosReducer from "./ecosReducer";
import innovReducer from "./innovReducer";
import respReducer from "./respReducer";
import promoReducer from "./promoReducer";
import healthInnovReducer from "./healthInnovReducer";
import serviceReducer from "./serviceReducer";
import appointmentReducer from "./appointmentReducer";
import smsReducer from "./smsReducer";
import messageReducer from "./messageReducer";
import emailReducer from "./emailReducer";
import notificationReducer from "./notificationReducer";
import fileUploadReducer from "./fileUploadReducer";
import videosReducer from "./videosReducer";

import { combineReducers } from "redux";

export default combineReducers({
  aboutReducer,
  authReducer,
  usersReducer,
  filesReducer,
  goalsReducer,
  alertReducer,
  programReducer,
  courseReducer,
  appointmentReducer,
  smsReducer,
  emailReducer,
  messageReducer,
  notificationReducer,
  fileUploadReducer,
  videosReducer,
  diplomaReducer,
  formationReducer,
  communicationReducer,
  publicationReducer,
  thesisReducer,
  seminarReducer,
  supportReducer,
  bookReducer,
  postCourseReducer,
  ecosReducer,
  innovReducer,
  serviceReducer,
  healthInnovReducer,
  respReducer,
  promoReducer
});
